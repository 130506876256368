import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="学生のあなたへ。人生を渡り切る強さをくれる名言！"
        pagepath="/feature/07/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/student01.jpg"
							alt="学生のあなたへ。人生を渡り切る強さをくれる名言！"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">学生のあなたへ。人生を渡り切る強さをくれる名言！</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>勉強、人間関係、部活。</span>
                        <span>人生で一番多感で忙しいときは学生時代かもしれません。</span>
                        <span>
                          たくさんの希望と悩みと、世界に対する好奇心。<br />
                          そんなもやもやを抱えるあなたに、力をくれる名言をご紹介します。
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>私たちは<span className="marker-b">教育</span>によって形作られています。</p>
                        <p>それは学校教育だけではありません。</p>
                        <p><span className="f-l">親の言葉、学校での友達の行動、テレビやラジオから流れてくる会話。</span></p>
                        <p>様々な外的要因から私たちは<span className="f-l">生き方やモラル</span>を学んでいきます。</p>
                        <p>それらの道徳規範は全て正しいのでしょうか？</p>
                        <p>親の言葉や、学校の先生の言葉を信じていれば必ず輝かしい未来が保障されているのでしょうか？</p>
                        <p>そんな事はありません。</p>
                        <p>人生とは、<span className="marker-b">自分が大切にする行動規範を見つける作業</span>と言ってもいいかもしれません。</p>
                        <p>その時に必要になるのは、ガンディーが言う、<span className="f-l">あなたの心に響く小さな声</span>だと思います。</p>
                        <p>正しい生き方をしてください。正しい知識を学びましょう。そして心の中の小さな声に耳を傾けましょう！</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>２０２１年は大谷翔平選手が、MLBを席巻した年です。</p>
                        <p>ベーブ・ルース以来の二刀流選手として大躍進しました。</p>
                        <p>大谷選手の活躍により、目に触れることが多くなったベーブ・ルースという人物。</p>
                        <p>彼も多くの名言を残したことで知られます。</p>
                        <p><span className="f-l">諦めないやつに勝てっこない。</span></p>
                        <p>素晴らしいですね。</p>
                        <p>人生において負けとは、<span className="marker-b">諦めた瞬間</span>に訪れます。</p>
                        <p>表面上、どんなに負けたとしても<span className="marker-b">諦めない限り勝負は続いています</span>。</p>
                        <p>この真理を知っていれば、<span className="marker-b">あなたの人生で負けはなくなります。</span></p>
                        <p>ただ、<span className="f-l">諦めずに努力すればいい</span>のです。</p>
                        <p>２０歳でも６０歳でも関係ありません。諦めなければ必ず勝てます。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>中華民国籍の日本の元プロ野球選手、王貞治。</p>
                        <p>１３年連続の本塁打王に輝くなど、日本プロ野球界の伝説です。</p>
                        <p>その輝かしいキャリアの裏には、<span className="marker-b">人知れぬ苦悩と努力の日々があった</span>ことでしょう。</p>
                        <p>記録や、華々しいホームランばかりがフィーチャーされがちですが、その背後には苦悩があることを忘れてはいけません。</p>
                        <p>長年、第一線で活躍し続けるとはどんな苦労があったことでしょう。</p>
                        <p>そんな、彼の人生を象徴するような言葉です。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>日本人メジャーリーガーの草分け的存在、野茂英雄。</p>
                        <p><span className="marker-b">MLBでの日本人選手の活躍の礎になったのは野茂</span>である事は間違いありません。</p>
                        <p>日本でプロとして活躍し、そして世界でもその活躍の場を広げた彼。</p>
                        <p>そんな彼から<span className="f-l">無駄が栄養</span>だと言う発言が出るのが面白いですね。</p>
                        <p>無駄を恐れずいろんなことにチャレンジする事は人生を充実させる大切な要因です。</p>
                        <p><span className="marker-y">『RANGE(レンジ)知識の「幅」が最強の武器になる』</span>と言う本には、<span className="marker-b">様々なジャンルを横断的に経験してきた人ほど成功する確率が上がる</span>ということが様々なデータをもとに展開されています。</p>
                        <p>まさに<span className="f-l">無駄こそが成功の母</span>です。</p>
                        <p>野茂英雄は経験からあるいは直感からその真実に気づいていたのでしょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>日本が世界に誇るアスリート、イチロー。</p>
                        <p>日本でバッターとしての栄華を極め、その後、MLBでも大活躍しました。</p>
                        <p>彼が特筆すべき点は、その<span className="f-l">現役の長さ</span>でしょう。</p>
                        <p>ただでさえ競争の激しいプロ野球って世界で、長年にわたり第一線で活躍することは並大抵のことではありません。</p>
                        <p>有名な話では、イチローは<span className="marker-b">毎日のルーティンを大切にする</span>ことで知られています。</p>
                        <p><span className="marker-y">朝カレー</span>は日本でもちょっとしたブームになりました。</p>
                        <p>彼独自のルーティンの形成の裏には、工夫と努力があった事は想像に難くありません。</p>
                        <p>無駄なことを考えて無駄なことをするという考え方、何のメリットもない行動をする勇気。</p>
                        <p>もしかしたら、イチローの1番の凄さがそこにあるのかも知れませんね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p><span className="f-l">七転び八起き</span>という素晴らしい諺が日本にはあります。</p>
                        <p>この言葉を座右の銘にしている人もいるのではないでしょうか。</p>
                        <p><span className="f-l">人生において失敗しないと言う事はあり得ません。</span></p>
                        <p>つまり誰もが必ず転ぶわけです。</p>
                        <p>そこまでは織り込み済み。大切なのはその後、<span className="marker-y">いかに早く起き上がるか</span>です。</p>
                        <p>早く起き上がれれば起き上がるほど、<span className="f-l">あなたは強くそして優秀になる</span>でしょう。</p>
                        <p>失敗してこけたときは、できるだけ早く起き上がることを考えましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>学校教育が苦手な偉人がいます。</p>
                        <p>その代表格はトーマスエジソンでしょう。</p>
                        <p>ADHD（注意欠陥多動性障害）を患っていたといわれており、</p>
                        <p>小学校の教師から『腐れ脳みそ』と罵倒されたエピソードが残っています。</p>
                        <p>後のエジソンの成功を鑑みると、<span className="marker-y">学校教育とは何なのか</span>と言うことを考えさせられます。</p>
                        <p>アインシュタインの名言も、学校教育に重きを置いていない感じがしますね。</p>
                        <p>強制的に習わされた学習よりも、<span className="f-l">自分で好奇心を持って追求する学習の方が価値がある</span>のは言うまでもありません。</p>
                        <p>あなたが、<span className="marker-y">学校教育で落第点を押されたとしても問題ありません。</span></p>
                        <p>この世界は減点方式で評価されるわけではありません。減点方式は学校の世界です。</p>
                        <p>あなたは、あなたが持っている<span className="f-l">得意で社会に貢献すれば良い</span>のです。その時にあなたの欠点があなたの足をひっぱることはありません。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>人類史上最強のの天才、レオナルド・ダ・ヴィンチ</p>
                        <p>イタリア人芸術家である彼は、その卓越した芸術的センスもさることながら、<span className="f-l">ジャンル横断的な専門性を発揮</span>したことで知られています。</p>
                        <p>レオナルド・ダ・ヴィンチの特徴を一言で言うならば、その<span className="f-l">興味の対象の広さと集中力の強さ</span>でしょう。</p>
                        <p><span className="marker-y">あらゆることに興味を持ち、驚異的な集中力でそれを学習する。</span></p>
                        <p>人生と言う短い時間の中で、彼が残した業績を鑑みるとそれ以外に理由がつけられません。</p>
                        <p>公教育と言う狭い枠の中で<span className="marker-b">好奇心を限定してはいけません。</span></p>
                        <p>ダヴィンチのように、<span className="marker-b">やりたいと思うことに時間と労力を割きましょう。</span></p>
                        <p>興味のある対象にしか時間を使ってはいけません。</p>
                        <p>それを繰り返していれば、あなたは第二のレオナルド・ダ・ヴィンチと呼ばれる日が来ます。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ニーチェは、ある概念を提唱しました。それが、<span className="f-l">超人思想</span>です。</p>
                        <p>簡単に説明するならば、<span className="marker-y">自分で物事の良し悪しを決められる、自分の価値観に従って生きていくことができる人のこと</span>です。</p>
                        <p><span className="f-l">なんだそんなことを、簡単じゃないか。僕もできているよ。</span></p>
                        <p>そんなふうに感じた方も多いのではないでしょうか。</p>
                        <p>しかし、ニーチェが定義する超人は非常に厳しい。</p>
                        <p>そして私たちは往々にして、<span className="marker-b">自分のエゴに支配され、物事の良し悪しを見誤り、自分の価値観を忘れて</span>生きてしまいます。</p>
                        <p>超人という言葉の背景には、人間の弱さを見越したニーチェの知恵が含まれています。</p>
                        <p>だからこそ私たちは超人を目指したいですね。そして、私たちには等しく超人になる強さを兼ね備えていると言うことを忘れてはなりません。</p>
                        <p>ニーチェは、この名言を残したのではないでしょうか。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ロシアの偉大な小説家、ドストエフスキー。</p>
                        <p>彼には壮絶な経験があります。</p>
                        <p>彼は<span className="f-l">死刑執行の直前で命を救われた経験</span>があるのです。</p>
                        <p>神から与えられた命を大切に思ったドストエフスキーはその後、猛烈な勢いで作品を書き上げます。</p>
                        <p>こうして生まれたのが<span className="marker-b">『罪と罰』や『カラマーゾフの兄弟』</span>などの、時代を超えて愛される名作です。</p>
                        <p>死、という最大の不幸、恐怖を経験した彼だからこそ、<span className="marker-b">幸福の本質</span>を理解することができたのでしょう。</p>
                        <p>この名言に関連する言葉としては、 日本の建築家、安藤忠雄も同じようなことを語っています。</p>
                        <p><span className="f-l">夢は達成するよりも、暗闇の中をもがき苦しみながら進んでいく過程にこそ、幸福がある</span>と著書で語っています。</p>
                        <p>夢を達成した後に燃え尽き、その後の人生をさまよってしまう成功者も数多く見られます。</p>
                        <p>ぜひ、その道中にこそ幸福があるのだと言うことを知り、あなたの活動の糧にしてください。</p>
                      </div>

                    </div>
                  </div>
                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "52939aab-b6aa-5c6c-ba1e-81b04648de7c"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "cd4f57a0-09f2-5d97-9542-e08c42ecdfda"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "87fe18f1-ec48-5cdb-ad13-dc541aece54a"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "2c41387c-8b66-518c-9e89-3a3e7af66dc4"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "431eb8fb-3b15-56b3-af98-b8cc1a8ae651"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "200c408b-36b2-5568-9438-2e2b4ce5bde1"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "28b92782-8abf-5d8d-bfe8-a8e39ffea9da"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "12c32af0-5650-502b-b845-17d4cb254f6b"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "0897feca-8d79-5143-a1bd-78a286df19a0"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "f59d9bf6-d77a-5e8a-b5ec-e221a1fe50d1"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home